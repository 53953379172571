import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Background } from 'react-imgix';
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/resource.scss';
import NavigationItem from '../components/NavigationItem';
import Aim from '../components/Aim';
import BottomQuoteCTA from '../components/BottomQuoteCTA';
import useInlineParsingV2 from '../utils/inline-parsing.utils';
import TeamMember from '../components/TeamMember';
import AdvisoryBoard from '../components/AdvisoryBoard';
import Partner from '../components/Partner';
import PublicationItem from '../components/PublicationItem';

const ResourceTemplate = ({ pageContext }) => {
  const [language, setLanguage] = useState('english');
  const { content, references, publications } = pageContext;
  
  const mountedContent = useInlineParsingV2(content);
  let mountedReferences = null;
  if (references !== null) {
    mountedReferences = useInlineParsingV2(references)
  }
  const { meta, page_contents, og_image, page_slug } = pageContext.metadata;
  // const frenchMountedContent = useInlineParsingV2(page_contents.metadata.french_content)
  return (
    <Layout pathName={pageContext.pathname}>
      <SEO pathname={page_slug} canonical={page_slug} title={meta.meta_title} description={meta.meta_description} ogImage={og_image.imgix_url || null} />
      <div className="resource__top-container">
        
        {page_contents.metadata.top_background_image && (
          <Background
            className="resource__image-background"
            imgixParams={{w: 1600, h: 852 }}
            src={`${page_contents.metadata.top_background_image.imgix_url}?auto=format`}
          />
        )}
        <div className="resource__contents-container">
          <div className="resource__contents-inner-container">
            {page_contents.metadata.french_content && page_contents.metadata.french_title && page_contents.metadata.french_content !== '' &&
              page_contents.metadata.french_title !== '' && (
              <div className="french_english__button">
                <button className={`${language === 'english' ? 'selected': ''}`} onClick={() => setLanguage('english')}>English</button>
                /
                <button className={`${language === 'french' ? 'selected': ''}`} onClick={() => setLanguage('french')}>Français</button>
              </div>
            )}
            <div className="resource__main-title" dangerouslySetInnerHTML={{ __html: language === 'english' ? page_contents.metadata.main_title : page_contents.metadata.french_title }} />
            <div className="resource__columned-content">
              {page_contents.metadata.column_1_content && (<div className="resource__column-1" dangerouslySetInnerHTML={{ __html: page_contents.metadata.column_1_content }} />)}
              {page_contents.metadata.column_2_content && (<div className="resource__column-2" dangerouslySetInnerHTML={{ __html: page_contents.metadata.column_2_content }} />)}

            </div>
          </div>
        </div>
      </div>
      <div className="gray-container">
        {page_contents.metadata.navigation_items && page_contents.metadata.navigation_items.items && (
          <section className="resource__stories-container">
            {page_contents.metadata.navigation_items.navigation_title !== '' && <h4 className="advisor-header">{page_contents.metadata.navigation_items.navigation_title}</h4>}
            {page_contents.metadata.navigation_items && page_contents.metadata.navigation_items.items.map((navItem, index) => (
              <NavigationItem
                key={`navigation-${index}`}
                url={navItem.metadata.page_slug}
                image={navItem.metadata.navigation_image.imgix_url}
                title={navItem.metadata.navigation_title}
                short_text={navItem.metadata.navigation_short_text}
              />
            ))}
          </section>
        )}
        {page_contents.metadata.aims_and_challenges && page_contents.metadata.aims_and_challenges.items && page_contents.metadata.aims_and_challenges.items.length > 0 && (
          <section className="resource__aims-challenges-container">
            <h4>{page_contents.metadata.aims_and_challenges.title}</h4>
            <div className="challenge-items-container">
              {page_contents.metadata.aims_and_challenges.items.map((item, index) => (
                <Aim
                  columnCount={page_contents.metadata.aims_and_challenges.items.length}
                  key={`aim-${index}`}
                  title={item.item_title}
                  content={item.item_description}
                />
              ))}
            </div>
          </section>
        )}
        {page_contents.metadata.advisor_list && page_contents.metadata.advisor_list.length > 0 && (
          <section className="resource__advisory-list-container">
            {page_contents.metadata.advisor_list.map((advisor, index) => (
              <div key={`publication-container-${index}`} className="resource__publication-container">
                {advisor.title && (<h4 className="advisor-header">{advisor.title}</h4>)}
                <div className="advisory-container">
                  {advisor.advisors.map((advisorItem, key) => (
                    <AdvisoryBoard key={`advisor-item-${index}-${key}`} advisoryMember={advisorItem} />
                  ))}
                </div>
              </div>
            ))}
          </section>
        )}
        {page_contents.metadata.rocket_science_team_list && page_contents.metadata.rocket_science_team_list.members && page_contents.metadata.rocket_science_team_list.members.length > 0 && (
          <section className="resource__team-members-container">
            <h4 className="team-header">{page_contents.metadata.rocket_science_team_list.title}</h4>
            <div className="team-members-container">
              {page_contents.metadata.rocket_science_team_list.members.map((member, index) => (
                <TeamMember member={member} key={`member-${index}`} />
              ))}
            </div>
          </section>
        )}
        {page_contents.metadata.advisory_board && page_contents.metadata.advisory_board.length > 0 && (
          <section className="resource__advisory-container">
            <h4 className="advisor-header">ADVISORY BOARD</h4>
            <div className="advisory-container">
              {page_contents.metadata.advisory_board.map((advisor, index) => (
                <AdvisoryBoard advisoryMember={advisor} key={`advisor-${index}`} />
              ))}
            </div>
          </section>
        )}
        {page_contents.metadata.supporters && page_contents.metadata.supporters.length > 0 && (
          <section className="resource__supporter-container">
            <h4 className="advisor-header">SUPPORTERS</h4>
            <div className="advisory-container">
              {page_contents.metadata.supporters.map((supporter, index) => (
                <Partner
                  content={supporter.metadata.supporter_description}
                  image={supporter.metadata.supporter_image.imgix_url}
                  title={supporter.title}
                  key={`supporter-${index}`}
                />
              ))}
            </div>
          </section>
        )}
        {page_contents.metadata.partners && page_contents.metadata.partners.length > 0 && (
          <section className="resource__supporter-container">
            <h4 className="advisor-header">PARTNERS</h4>
            <div className="advisory-container">
              {page_contents.metadata.partners.map((partner, index) => (
                <Partner
                  title={partner.title}
                  content={partner.metadata.partner_description}
                  image={partner.metadata.partner_image.imgix_url}
                  key={`partner-${index}`}
                />
              ))}
            </div>
          </section>
        )}
        {page_contents.content && mountedContent && (
          <section className="resource__content-container">
            {language === 'english' && mountedContent.length > 0 && mountedContent.map((articleContent, index) => {
              switch (articleContent.type) {
                case 'html':
                  return (
                    <div
                      key={`${articleContent.type}-${index}`}
                      id={`${articleContent.index}`}
                      className="resource__main-content"
                      dangerouslySetInnerHTML={{ __html: articleContent.content }}
                    />
                  );
                case 'jsx':
                  return articleContent.content;
                default:
                  return '';
              }
            })}
            {language === 'french' && (
              <div
                className="resource__main-content"
                dangerouslySetInnerHTML={{ __html: page_contents.metadata.french_content }}
              />
            )}
          </section>
        )}
        {publications && publications.length > 0 && (
          <section className="resource__publication-list-container">
            {publications.map((publicationItem, index) => (
              <div key={`publication-container-${index}`} className="resource__publication-container">
                {publicationItem.header !== '' &&  (<h4 className="advisor-header">{publicationItem.header}</h4>)}
                {publicationItem.publications && publicationItem.publications[0].metadata && publicationItem.publications[0].metadata.category !== '' && <h3 className="publication-subheader">{publicationItem.publications[0].metadata.category}</h3>}
                <div className="resource__publications">
                  {publicationItem.publications && publicationItem.publications.map((publication, key) => (
                    <PublicationItem key={`publication-item-${index}-${key}`} publication={publication} has_section_image={publicationItem.section_has_images} />
                  ))}
                </div>
              </div>
            ))}
          </section>
        )}
        {page_contents.metadata.quote && page_contents.metadata.quote.quote_text && (
          <section className="resource__quote-container">
            <BottomQuoteCTA
              image={page_contents.metadata.quote.image !== null && page_contents.metadata.quote.image !== undefined ? page_contents.metadata.quote.image.imgix_url : null}
              quote={page_contents.metadata.quote.quote_text}
              author={page_contents.metadata.quote.author}
            />
          </section>
        )}
        
        {page_contents.metadata.references && mountedReferences && (
          <section className="resource__references-container">
            <h4 className="advisor-header">REFERENCES</h4>
            <div className="resource__reference-text-container">
              {mountedReferences.length > 0 && mountedReferences.map((mountedReference, index) => {
                switch (mountedReference.type) {
                  case 'html':
                    return (
                      <div
                        key={`${mountedReference.type}-${index}`}
                        id={`${mountedReference.index}`}
                        className="resource__main-content"
                        dangerouslySetInnerHTML={{ __html: mountedReference.content }}
                      />
                    );
                  case 'jsx':
                    return mountedReference.content;
                  default:
                    return '';
                }
              })}
            </div>
          </section>
        )}
      </div>
    </Layout>
  )
};

ResourceTemplate.propTypes = {
  pageContext: PropTypes.any,
};

ResourceTemplate.defaultProps = {
  pageContext: {},
};

export default ResourceTemplate;
